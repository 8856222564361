import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoadingService } from './loading.service';

/**
 *
 * HTTP interceptor to handle 401's and redirect them back to login
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  /**
   * Constructor
   * @param router - the angular router to navigate
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  /**
   * Intercept all responses to check for 401
   * @param request - the http request
   * @param next - http handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.search(/search/i) === -1 // Needed to stop loading the app on typeahead searches
    ) {
      this.loadingService.startLoading();
    }
    return next.handle(request)
      .pipe(
        tap(() => { }, (error: any) => {
          if (error.status === 401 && !(error.error && error.error.user)) {
            // route back to login
            localStorage.removeItem('cix_admin_userId');
            localStorage.removeItem('cixhealthAdmin');
            this.reRouteUser();
          }
          if (error.status === 404 && error?.error?.UserDoesNotExist) {
            this.reRouteUser();
          }
        }),
        finalize(() => {
          if (
            request.url.search(/updateFirebaseToken/i) === -1 // stop loading on update firebase token
          ) {
            this.loadingService.endLoading();
          }
        })
      );
  }

  reRouteUser() {
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      if (params) {
        const toSend: NavigationExtras = { queryParams: {} };
        params.keys
          .map((key: string) => {
            if (toSend?.queryParams) {
              toSend.queryParams[key] = params.get(key);
            }
          });
        if (toSend?.queryParams) {
          toSend.queryParams.route = this.router.url.toLowerCase().split('/')[1]?.split('?')[0] === 'login'
            ? toSend.queryParams.route : this.router.url.toLowerCase().split('/')[1]?.split('?')[0];
        }
        this.router.navigate(['/login'], toSend);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
}
