import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

// import { SnackData } from '@utils';
export interface SnackData {
    contentText: string;
    showButton: boolean;
    hideIcon?: boolean;
}

@Component({
    selector: 'app-snack-error',
    templateUrl: './snack.error.html',
    styleUrls: ['./snack.error.scss']
})
export class SnackErrorComponent implements OnInit {
    darkMode: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackData,
        private snackRef: MatSnackBarRef<SnackErrorComponent>,
    ) { }

    ngOnInit() {
        if (!this.data.contentText) {
            this.data.contentText = 'Opps, an error occurred.';
        }
    }

    dismiss() {
        this.snackRef.dismiss();
    }

    emitActionClick() {
        this.snackRef.dismissWithAction();
    }
}
