import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { LoadingComponent } from './loading.component';


@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        LoadingComponent
    ],
    declarations: [
        LoadingComponent
    ],
    providers: [
    ],
})
export class LoadingModule { }
